import { VolumeDiscountsState } from './VolumeDiscounts';
import { ProductExtraGuaranteeState } from '~/types/vuex/ProductExtraGuarantee';
import { NavigationState } from '~/types/vuex/Navigation';
import { ClientIPState } from '~/types/vuex/ClientIP';
import { ClientEmailState } from '~/types/vuex/ClientEmail';
import { AnchorNavState } from '~/types/vuex/AnchorNav';
import { CartTrackingState } from '~/types/vuex/CartTracking';
import { ComplementaryProductsState } from '~/types/vuex/ComplementaryProducts';
import { AdyenErrorState } from '~/types/vuex/AdyenError';
import { CartSidebarState } from '~/types/vuex/CartSidebar';
import { PaymentSelectedState } from '~/types/vuex/PaymentSelected';
import { ShippingAddressTypeState } from '~/types/vuex/ShippingAddressType';
import { ShippingDetailsState } from '~/types/vuex/ShippingDetails';
import { BillingDetailsState } from '~/types/vuex/BillingDetails';
import { UiNotificationState } from '~/types/vuex/UiNotification';
import { ExtraGuaranteeSidebarState } from '~/types/vuex/ExtraGuaranteeSidebar';
import { ReviewExtendedState } from '~/types/vuex/ReviewExtended';
import { PaginationState } from '~/types/vuex/Pagination';
import { FilterModalUiState } from '~/types/vuex/FilterModalUi';

export const MODULE_NAMES = {
  EXTRA_GUARANTEE_MODULE_NAME: 'productExtraGuarantee',
  NAVIGATION_MODULE_NAME: 'navigation',
  CLIENT_IP_MODULE_NAME: 'clientIP',
  CLIENT_EMAIL_MODULE_NAME: 'clientEmail',
  ADYEN_ERROR: 'adyenError',
  ANCHOR_NAV_MODULE_NAME: 'anchorNav',
  CART_TRACKING_MODULE_NAME: 'cartTracking',
  COMPLEMENTARY_PRODUCTS_MODULE_NAME: 'complementaryProducts',
  VOLUME_DISCOUNTS_MODULE_NAME: 'volumeDiscounts',
  CART_SIDE_BAR_MODULE_NAME: 'cartSidebar',
  PAYMENT_SELECTED_MODULE_NAME: 'paymentSelected',
  SHIPPING_ADDRESS_TYPE_MODULE_NAME: 'shippingAddressType',
  SHIPPING_DETAILS_MODULE_NAME: 'shippingDetails',
  BILLING_DETAILS_MODULE_NAME: 'billingDetails',
  UI_NOTIFICATION_MODULE_NAME: 'uiNotification',
  EXTRA_GUARANTEE_SIDEBAR_MODULE_NAME: 'extraGuaranteeSidebar',
  REVIEW_EXTENDED_MODULE_NAME: 'reviewExtended',
  PAGINATION_MODULE_NAME: 'pagination',
  FILTER_MODAL_UI: 'filterModalUi'
} as const;

export type RootState = {
  [MODULE_NAMES.EXTRA_GUARANTEE_MODULE_NAME]: ProductExtraGuaranteeState
  [MODULE_NAMES.NAVIGATION_MODULE_NAME]: NavigationState,
  [MODULE_NAMES.CLIENT_IP_MODULE_NAME]: ClientIPState,
  [MODULE_NAMES.CLIENT_EMAIL_MODULE_NAME]: ClientEmailState,
  [MODULE_NAMES.ADYEN_ERROR]: AdyenErrorState,
  [MODULE_NAMES.ANCHOR_NAV_MODULE_NAME]: AnchorNavState,
  [MODULE_NAMES.CART_TRACKING_MODULE_NAME]: CartTrackingState,
  [MODULE_NAMES.COMPLEMENTARY_PRODUCTS_MODULE_NAME]: ComplementaryProductsState
  [MODULE_NAMES.VOLUME_DISCOUNTS_MODULE_NAME]: VolumeDiscountsState,
  [MODULE_NAMES.CART_SIDE_BAR_MODULE_NAME]: CartSidebarState,
  [MODULE_NAMES.PAYMENT_SELECTED_MODULE_NAME]: PaymentSelectedState,
  [MODULE_NAMES.SHIPPING_ADDRESS_TYPE_MODULE_NAME]: ShippingAddressTypeState,
  [MODULE_NAMES.SHIPPING_DETAILS_MODULE_NAME]: ShippingDetailsState,
  [MODULE_NAMES.BILLING_DETAILS_MODULE_NAME]: BillingDetailsState,
  [MODULE_NAMES.UI_NOTIFICATION_MODULE_NAME]: UiNotificationState,
  [MODULE_NAMES.EXTRA_GUARANTEE_SIDEBAR_MODULE_NAME]: ExtraGuaranteeSidebarState,
  [MODULE_NAMES.REVIEW_EXTENDED_MODULE_NAME]: ReviewExtendedState,
  [MODULE_NAMES.PAGINATION_MODULE_NAME]: PaginationState
  [MODULE_NAMES.FILTER_MODAL_UI]: FilterModalUiState
};

export interface ExtendedState {
  getterKeys?: string[],
  mutationKeys?: string[],
  actionKeys?: string[]
}
