/**
 * @typedef {import('node_modules/@vue/test-utils/types').Wrapper} Wrapper
 * @param { string } topLevelTitle
 * @param { (unknown) => Wrapper } createWrapper
 * @param { import('../../types/tests/TestCase').TestCase[] } testCasesForSnapshots
 */
export default (
  topLevelTitle,
  createWrapper,
  testCasesForSnapshots
) => {
  describe(topLevelTitle, () => {
    testCasesForSnapshots.forEach(testCase => {
      describe(testCase.condition + ', ', () => {
        test(testCase.assertion, async () => {
          const wrapper = await createWrapper(testCase.data);
          expect(wrapper.element).toMatchSnapshot();
        });
      });
    });
  });
};
