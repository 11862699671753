






import Vue from 'vue';
import type { PropType } from '@nuxtjs/composition-api';
import PaymentMethodIcons from '~/components/atoms/Payment/PaymentMethodIcons.vue';
import { PaymentMethodIcon } from '~/types/cms/PaymentIcons';

export default Vue.extend({
  name: 'TrustedPayments',
  components: {
    PaymentMethodIcons
  },
  props: {
    paymentMethodIcons: {
      type: Array as PropType<PaymentMethodIcon[]>,
      required: true
    }
  }
});
