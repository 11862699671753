import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import type {
  AgnosticPrice,
  ProductGettersFilters,
  ProductMediaGalleryItem,
  AgnosticAttribute
} from '~/utils/ct';
import {
  createPrice,
  getVariantByAttributes,
  normalizeProductList,
  getFilteredAttributes,
  getUniqueAttributes,
  mapAttributesToRecord
} from '~/utils/ct/composables/helpers/productAttributes';

function getName(product: ProductVariantExtended): string {
  return product?._name ?? '';
}

function getSlug(product: ProductVariantExtended): string {
  return product?._slug ?? '';
}

function getPrice(product: ProductVariantExtended): AgnosticPrice {
  return createPrice(product);
}

function getGallery(product: ProductVariantExtended): ProductMediaGalleryItem[] {
  const images = product?.images ?? [];
  return images.map(image => ({
    small: image.url,
    big: image.url,
    normal: image.url
  }));
}

function getCoverImage(product: ProductVariantExtended): string {
  return product?.images?.[0]?.url ?? '';
}

function getFiltered(
  products: ProductVariantExtended[],
  filters: ProductGettersFilters = {}
): ProductVariantExtended[] {
  if (!products) return [];

  if (filters?.skus) {
    return products.filter(product => filters.skus.includes(product.sku));
  }
  if (filters?.attributes && Object.keys(filters.attributes).length > 0) {
    return [getVariantByAttributes(products, filters.attributes)];
  }
  if (filters?.master) {
    return products.filter(product => product._master);
  }
  return products;
}

function getAttributes(
  products: ProductVariantExtended[] | ProductVariantExtended,
  filterByAttributeName?: string[]
): Record<string, AgnosticAttribute | string> {
  const isSingleProduct = !Array.isArray(products);
  const productList = normalizeProductList(products);

  if (!productList.length) return {};

  const filteredAttributes = getFilteredAttributes(productList, filterByAttributeName);
  const uniqueAttributes = getUniqueAttributes(filteredAttributes);

  return mapAttributesToRecord(uniqueAttributes, isSingleProduct);
}

function getDescription(product: ProductVariantExtended): string {
  return product?._description || '';
}

function getId(product: ProductVariantExtended): string {
  return product?._id || '';
}

function getSku(product: ProductVariantExtended): string {
  return product?.sku || '';
}

function getFormattedPrice(price: number): string {
  return price.toString();
}

function getTotalReviews(product: ProductVariantExtended): number {
  return product?._rating?.count || 0;
}

function getAverageRating(product: ProductVariantExtended): number {
  return product?._rating?.averageRating || 0;
}

export const productGetters = {
  getName,
  getSlug,
  getPrice,
  getGallery,
  getCoverImage,
  getFiltered,
  getAttributes,
  getDescription,
  getId,
  getSku,
  getFormattedPrice,
  getTotalReviews,
  getAverageRating
};
