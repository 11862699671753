

















import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { PaymentMethodIcon } from '~/types/cms/PaymentIcons';
import PaymentIcon from '~/components/atoms/Payment/PaymentIcon.vue';

export default defineComponent({
  name: 'PaymentMethodIcons',
  components: {
    PaymentIcon
  },
  props: {
    icons: {
      type: Array as PropType<PaymentMethodIcon[]>,
      required: true
    }
  }
});
