import type { ApiResponse, PaginationParams } from '~/utils/ct';

export const catchApiErrors = <T>({ graphQLErrors, networkError, simple = false }: ApiResponse<T>) => {
  if (graphQLErrors && graphQLErrors.length) {
    throw simple ? new Error(graphQLErrors[0]?.message) : graphQLErrors[0];
  }
  if (networkError) {
    throw networkError;
  }
};

export function mapPaginationParams<T extends PaginationParams>({ page, perPage }: T) {
  if (perPage && page) {
    return {
      limit: perPage,
      offset: (page - 1) * perPage
    };
  }
}
