import _renderToString from 'vue-server-renderer/basic';
import qs from 'qs';
import Vue from 'vue';
import removeIndexNameFromQuery from './removeIndexNameFromQuery';
import addIndexNameToQuery from './addIndexNameToQuery';
import getAdjustedQuery from '~/helpers/algolia/algoliaSSR/getAdjustedQuery';
import { isSearchPageSlug } from '~/helpers/routes/getPageInformation';
import filterRouteForLinks from '~/helpers/algolia/algoliaSSR/filterRouteForLinks/filterRouteForLinks';

// important for using i18n, router and store in algolia -
// https://github.com/algolia/vue-instantsearch/issues/936#issuecomment-807074775
export function $cloneComponent(componentInstance, { mixins = [] } = {}) {
  const options = {
    serverPrefetch: undefined,
    fetch: undefined,
    _base: undefined,
    name: 'ais-ssr-root-component',
    i18n: componentInstance.$i18n,
    router: componentInstance.$router,
    store: componentInstance.$store
  };

  const Extended = componentInstance.$vnode
    ? componentInstance.$vnode.componentOptions.Ctor.extend(options)
    : Vue.component(
      options.name,
      Object.assign({}, componentInstance.$options, options)
    );

  const app = new Extended({
    propsData: componentInstance.$options.propsData,
    mixins: [...mixins]
  });

  app.$slots = componentInstance.$slots;
  app.$root = componentInstance.$root;
  app.$options.serverPrefetch = [];

  return app;
}

const getDefaultQueryParams = () => ({
  query: ''
});

const getUpdatedRouterStateInfo = (routeState) => {
  let shouldClearQueryParams = true;
  for (const searchFilters of Object.values(routeState)) {
    for (const searchFilterValue of Object.values(searchFilters)) {
      if (searchFilterValue) {
        shouldClearQueryParams = false;
        break;
      }
    }
  }
  const currentRouteState = shouldClearQueryParams ? getDefaultQueryParams() : routeState;

  return {
    shouldClearQueryParams,
    currentRouteState
  };
};

// algolia router - https://www.algolia.com/doc/guides/building-search-ui/upgrade-guides/vue/#vue-router-v4
export const algoliaRouter = (vueRouter, isSSRMode, algoliaIndexName) => ({
  read() {
    const windowLocation = typeof window === 'object' ? window.location.href : '';
    const path = windowLocation || vueRouter?.currentRoute.fullPath;
    const search = path.includes('?') ? path.split('?')[1] : '';

    const searchQuery = qs.parse(search, {
      ignoreQueryPrefix: true
    });
    return addIndexNameToQuery(searchQuery, algoliaIndexName);
  },
  write(routeState) {
    const tempIsSSR = isSSRMode;
    isSSRMode = false;

    const isOnSearchPage = isSearchPageSlug(vueRouter.currentRoute.path);
    const { shouldClearQueryParams, currentRouteState } = getUpdatedRouterStateInfo(routeState);

    // Only push a new entry if the URL changed (avoid duplicated entries in the history)
    if (this.createURL(currentRouteState) === this.createURL(this.read()) && !shouldClearQueryParams) {
      return;
    }

    let adjustedQuery = getAdjustedQuery(vueRouter, currentRouteState, tempIsSSR, algoliaIndexName);
    if (shouldClearQueryParams && !isOnSearchPage) {
      adjustedQuery = vueRouter.currentRoute.path;
    }

    if (typeof history === 'object') {
      history.replaceState(currentRouteState, null, adjustedQuery);
    }
  },
  createURL(routeState) {
    routeState = removeIndexNameFromQuery(routeState, algoliaIndexName);
    routeState = filterRouteForLinks(routeState);
    return qs.stringify(routeState, {
      addQueryPrefix: true
    });
  },
  onUpdate(callback) {
    if (typeof window !== 'object') {
      return;
    }

    this._onPopState = () => {
      if (this.writeTimer) {
        window.clearTimeout(this.writeTimer);
        this.writeTimer = undefined;
      }
      callback(this.read());
    };
    window.addEventListener('popstate', this._onPopState);
  },
  dispose() {
    if (this._onPopState && typeof window === 'object') {
      window.removeEventListener('popstate', this._onPopState);
    }
    // we purposely don't write on dispose, to prevent double entries on navigation
  }
});

export const stateMapping = (indexName, defaultRefinements = {}) => ({

  stateToRoute(uiState) {
    const routeState = {};
    const state = uiState[indexName];

    for (const [key] of Object.entries(defaultRefinements)) {
      routeState[key] =
      state.refinementList &&
      state.refinementList[key]
        ? state.refinementList[key][0]
        : '';
    }

    return routeState;
  },
  routeToState(routeState) {
    const refinements = {};

    for (const [key, defaultValue] of Object.entries(defaultRefinements)) {
      refinements[key] = routeState[key] ? [routeState[key]] : [defaultValue || ''];
    }

    return {
      [indexName]: {
        refinementList: refinements
      }
    };
  }
});
// doc- https://www.algolia.com/doc/guides/building-search-ui/going-further/server-side-rendering/vue/#with-nuxt
export function renderToString(app) {
  return new Promise((resolve, reject) => {
    _renderToString(app, (err, res) => {
      if (err) reject(err);
      resolve(res);
    });
  });
}
