import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  FilterModalUiMutations
} from '~/types/vuex/FilterModalUi';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.FILTER_MODAL_UI,
    FilterModalUiMutations,
    {},
    {}
  >(MODULE_NAMES.FILTER_MODAL_UI);
  const isFilterModalOpen = computed(() => store.state.isFilterModalOpen);
  return {
    isFilterModalOpen,
    ...store.getters,
    ...store.mutations,
    ...store.actions
  };
}
