import { reactive, computed } from '@nuxtjs/composition-api';
import {
  useStoreVsfExtended,
  useExtraGuaranteeSidebar,
  useFilterModalUi
} from '~/composables';

const state = reactive({
  isWishlistSidebarOpen: false,
  isComplementarySidebarOpen: false
});

const useUiState = () => {
  const { isExtraGuaranteeOpen, setIsExtraGuaranteeOpen } = useExtraGuaranteeSidebar();
  const { isComplementarySidebarEnabled } = useStoreVsfExtended();
  const { isFilterModalOpen, setFilterModalOpen } = useFilterModalUi();

  const isWishlistSidebarOpen = computed(() => state.isWishlistSidebarOpen);
  const isComplementarySidebarOpen = computed(() =>
    state.isComplementarySidebarOpen && isComplementarySidebarEnabled.value);

  const toggleWishlistSidebar = () => {
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleFilterModal = () => {
    setFilterModalOpen(!isFilterModalOpen.value);
  };

  const toggleComplementarySidebar = () => {
    state.isComplementarySidebarOpen = !state.isComplementarySidebarOpen;
  };

  const toggleExtraGuarantee = () => {
    setIsExtraGuaranteeOpen(!isExtraGuaranteeOpen.value);
  };

  return {
    isWishlistSidebarOpen,
    isFilterModalOpen,
    toggleWishlistSidebar,
    toggleFilterModal,
    isExtraGuaranteeOpen,
    toggleExtraGuarantee,
    isComplementarySidebarOpen,
    toggleComplementarySidebar
  };
};

export default useUiState;
