



import { defineComponent } from '@nuxtjs/composition-api';
import Newsletter from '~/components/molecules/Newsletter/Newsletter.vue';
export default defineComponent({
  name: 'NewsletterCms',
  components: {
    Newsletter
  }
});
