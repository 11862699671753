import { shallowMount } from '@vue/test-utils';
import NewsletterCms from '../NewsletterCms.vue';
import testCaseWrapper from '~/helpers/test/testCaseWrapper';

function createWrapper() {
  return shallowMount(NewsletterCms);
}

const testCasesForSnapshots = [
  {
    condition: 'when component is used',
    assertion: 'renders Newsletter'
  }
];

testCaseWrapper(
  'NewsletterCms.vue',
  createWrapper,
  testCasesForSnapshots
);
