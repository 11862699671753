













































































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { Logger } from '@vue-storefront/core';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ROUTES } from '~/constants/routes';
import { useIntegrations, useRouteExtended } from '~/composables';
import Button from '~/components/atoms/Buttons/Button/Button.vue';

export default defineComponent({
  components: {
    ValidationProvider,
    ValidationObserver,
    Button
  },
  setup() {
    const { $cia } = useIntegrations();
    const { getAdjustedSlug } = useRouteExtended();
    const newsletterLink = computed(() => getAdjustedSlug(ROUTES.PRIVACY));
    const email = ref('');
    const isUserSignedUp = ref(false);
    const isError = ref(false);
    const isSending = ref(false);
    const isNewsletterTouched = ref(false);

    const handleSendingEmailSubscription = () => {
      if (!email.value) {
        isNewsletterTouched.value = true;
        return;
      }
      isSending.value = true;
      $cia.event.newsletterDOI(email.value).then(() => {
        isUserSignedUp.value = true;
        isSending.value = false;
      }).catch((error) => {
        Logger.error(`cia|newsletterDOI error: ${error}`);
        isError.value = true;
        isSending.value = false;
      });
    };

    return {
      email,
      isUserSignedUp,
      isError,
      isSending,
      handleSendingEmailSubscription,
      newsletterLink,
      isNewsletterTouched
    };
  }
});
