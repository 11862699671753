import { cartGetters } from '~/utils/ct/getters/cartGetters';
import type {
  DiscountedProductPriceValue,
  RawProductAttribute,
  AgnosticPrice,
  Price,
  ProductOrLineItem,
  AgnosticAttribute
} from '~/utils/ct';
import { ProductVariantExtended } from '~/types/product/ProductVariantExtended';

const isLineItem = (
  product: ProductOrLineItem
) => product?.__typename === 'LineItem';

const getPrice = (
  price: Maybe<Price>
): number | null => price?.value?.centAmount ? price.value.centAmount / 100 : null;

const getDiscount = (
  product: ProductOrLineItem
): DiscountedProductPriceValue => product?.price?.discounted;

const getSpecialPrice = (product: ProductOrLineItem): number | null => {
  const discount = getDiscount(product);

  if (isLineItem(product)) {
    const lastDiscountedPrice = product.discountedPricePerQuantity?.at(-1)?.discountedPrice;
    if (lastDiscountedPrice) {
      return getPrice(lastDiscountedPrice);
    }
  }

  return discount?.discount?.isActive ? getPrice(discount) : null;
};

const getAttributeValue = (attribute: RawProductAttribute) => {
  const attributeType = attribute?.attributeDefinition?.type?.name;

  switch (attributeType) {
    case 'text':
    case 'ltext':
    case 'boolean':
    case 'number':
    case 'date':
    case 'time':
    case 'datetime':
    case 'money':
    case 'set':
      return attribute?.value;

    case 'lenum':
    case 'enum':
      return attribute?.value?.key;

    case 'reference':
      return attribute?.value ? { typeId: attribute?.value?.typeId, id: attribute?.value?.id } : null;

    default:
      return null;
  }
};

export const formatAttributeList = (
  attributes: RawProductAttribute[]
) => attributes.map((attr) => {
  const attrValue = getAttributeValue(attr);
  return {
    name: attr.name,
    value: attrValue,
    label: attr?._translated || ''
  };
});

export const getVariantByAttributes = (
  products: ProductVariantExtended[], attributes: RawProductAttribute[]
) => {
  if (!products?.length || !attributes?.length) return null;

  return products.find(product => {
    const currentAttributes = formatAttributeList(product?.attributesRaw ?? []);

    return attributes.every(({ name, value }) =>
      currentAttributes.some(({ name: currentName, value: currentValue }) =>
        currentName === name && currentValue === value
      )
    );
  });
};

export const createPrice = (product): AgnosticPrice => {
  if (!product) {
    return { regular: null, special: null };
  }

  const quantity = isLineItem(product) ? cartGetters.getItemQty(product) : 1;

  const regularPrice = (getPrice(product.price) ?? 0) * quantity;
  const specialPrice = (getSpecialPrice(product) ?? 0) * quantity;

  return {
    regular: regularPrice || null,
    special: specialPrice || null
  };
};

export const normalizeProductList = (
  products: ProductVariantExtended[] | ProductVariantExtended
): ProductVariantExtended[] => {
  return Array.isArray(products) ? products : [products];
};

export const getFilteredAttributes = (
  productList: ProductVariantExtended[],
  filterByAttributeName?: string[]
): RawProductAttribute[] => {
  return productList.flatMap(product =>
    formatAttributeList(product?.attributesRaw ?? []).filter(
      attribute => !filterByAttributeName || filterByAttributeName.includes(attribute.name)
    )
  );
};

export const getUniqueAttributes = (
  attributes: RawProductAttribute[]
): RawProductAttribute[] => {
  return attributes.reduce((uniqueAttrs, attr) => {
    if (!uniqueAttrs.some(({ name, value }) => name === attr.name && value === attr.value)) {
      uniqueAttrs.push(attr);
    }
    return uniqueAttrs;
  }, [] as RawProductAttribute[]);
};

const isAgnosticAttributeArray = (
  value: string | AgnosticAttribute | AgnosticAttribute[] | undefined
): value is AgnosticAttribute[] => {
  return Array.isArray(value) && value.every(item => 'value' in item && 'label' in item);
};

export const mapAttributesToRecord = (
  attributes: RawProductAttribute[],
  isSingleProduct: boolean
): Record<string, AgnosticAttribute | string> => {
  const result: Record<string, AgnosticAttribute | string> = {};

  return attributes.reduce((acc, { name, value, label }) => {
    if (isSingleProduct) {
      acc[name] = value;
    } else {
      if (!isAgnosticAttributeArray(acc[name])) {
        acc[name] = [] as undefined as AgnosticAttribute;
      }
      (acc[name] as AgnosticAttribute[]).push({ value, label });
    }
    return acc;
  }, result);
};
