









import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { PaymentMethodIcon } from '~/types/cms/PaymentIcons';
import cmsImageToUrl from '@/helpers/cms/cmsImageToUrl';

export default defineComponent({
  name: 'PaymentIcon',
  props: {
    icon: {
      type: Object as PropType<PaymentMethodIcon>,
      required: true
    }
  },
  setup(props) {
    const iconSrc = computed(() => cmsImageToUrl(props.icon?.image));

    return { iconSrc };
  }
});
