import {
  Scalars,
  LocalizedString,
  Maybe,
  TextInputHint
} from '~/utils/ct';

export const attributeType = {
  STRING: 'StringAttribute',
  DATE: 'DateAttribute',
  DATETIME: 'DateTimeAttribute',
  TIME: 'TimeAttribute',
  NUMBER: 'NumberAttribute',
  ENUM: 'EnumAttribute',
  LOCALIZED_ENUM: 'LocalizedEnumAttribute',
  LOCALIZED_STRING: 'LocalizedStringAttribute',
  MONEY: 'MoneyAttribute',
  BOOLEAN: 'BooleanAttribute'
} as const;

export type AttributeType = ValueOf<typeof attributeType>;

export const attributeConstraint = {
  None: 'None',
  Unique: 'Unique',
  CombinationUnique: 'CombinationUnique',
  SameForAll: 'SameForAll'
} as const;

export type AttributeConstraint = keyof typeof attributeConstraint;

export type AttributeDefinitionType = {
  name: Scalars['String'];
};

export type AttributeDefinition = {
  __typename?: 'AttributeDefinition';
  type: AttributeDefinitionType;
  name: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  isRequired: Scalars['Boolean'];
  attributeConstraint: AttributeConstraint;
  inputTip?: Maybe<Scalars['String']>;
  inputHint: TextInputHint;
  isSearchable: Scalars['Boolean'];
  labelAllLocales: Array<LocalizedString>;
  inputTipAllLocales?: Maybe<Array<LocalizedString>>;
};

export type AttributeDefinitionResult = {
  __typename?: 'AttributeDefinitionResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  results: Array<AttributeDefinition>;
};
