import { Logger, sharedRef } from '@vue-storefront/core';
import { computed } from '@nuxtjs/composition-api';
import { useProductApi } from '~/utils/ct/composables/api/useProductApi';
import type { ApiParams } from '~/utils/ct';
import { errorToString } from '~/helpers/error/errorToString';

export function useProduct<ProductType>(id: string) {
  const { search: apiSearch } = useProductApi();
  const products = sharedRef<
    { data: {products: {results: ProductType[] }}} | null
  >(null, `useProductProducts-${id}`);
  const loading = sharedRef(false, `useProductLoading-${id}`);
  const error = sharedRef<{
    search: null | string
  }>({
    search: null
  }, `useProductError-${id}`);

  async function search(searchParams: ApiParams) {
    try {
      loading.value = true;
      products.value = await apiSearch(searchParams);
      error.value.search = null;
    } catch (err: unknown) {
      error.value.search = errorToString(err);
      Logger.error(`useProduct.search id:${id}`, err);
    } finally {
      loading.value = false;
    }
  }

  return {
    search,
    products: computed(() => products.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
}
